<template>
  <b-card>
    <b-row
      align-h="between"
      align-v="center"
    >
      <b-col
        sm="4"
        class="text-left"
      >
        <h4 class="m-0 mt-3 mb-3">
          {{ resourceName }}
        </h4>
      </b-col>
    </b-row>
    <vue-good-table
      class="position-static"
      mode="remote"
      :columns="columns"
      :rows="rows"
      :rtl="directionIsRTL"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: perPage,
      }"
      style-class="vgt-table striped"
      :is-loading="isLoading"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
    >
      <!-- Headers -->
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'checked'">
          <b-form-checkbox
            v-model="selectAll"
            :value="true"
            :unchecked-value="false"
            class="mr-2 vgt-checkbox-col"
          />
        </span>
        <span v-else>
          {{ props.column.label }}
        </span>
      </template>
      <template slot="emptystate">
        <div
          v-if="isLoading"
          class="text-center"
        >
          Fetching data
        </div>
        <div
          v-else
          class="text-center"
        >
          No data for table
        </div>
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <div v-if="props.column.field === 'participants'">
          <router-link
            :to="{
              name: 'champion-program-participant',
              params: {
                id: defaultProgramId,
                participantId: props.row.participantId,
              },
            }"
            class="table-primary-link"
          >
            {{ props.formattedRow[props.column.field] }}
          </router-link>
        </div>
        <span
          v-else-if="props.column.field === 'role'"
          class="text-capitalize"
        >
          {{ props.formattedRow[props.column.field] }}
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <table-pagination
          :per-page="perPage"
          :total="total"
          @perPageChanged="
            (value) => props.perPageChanged({ currentPerPage: value })
          "
          @pageChanged="(value) => props.pageChanged({ currentPage: value })"
        />
      </template>
    </vue-good-table>
  </b-card>
</template>
<script>
import { mapGetters } from "vuex";
import {
  VBToggle,
  VBTooltip,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import {
  GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
  GOODTABLE_LOCALISED_DATE_FORMAT,
  GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT,
  PRIMARY_DATE_FORMAT,
} from "@/libs/utils";
import { userRoles, formStatus, formStatusDisplay } from "@/models";
import useAomTableConfig from "@aom-core/useAomTableConfig.js";
import flatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";
import resourcesService from "@/services/resourcesService";
export default {
  components: {
    VueGoodTable,
    BCard,
    BRow,
    BCol,
    TablePagination,
    BFormCheckbox,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      isLoading: false,
      element: {},
      dir: false,
      columns: [
        {
          label: "Participants",
          field: "participants",
          filterOptions: {
            enabled: true,
            placeholder: "Search Participants",
          },
          width: "10em",
        },
        {
          label: "Role",
          field: "role",
          filterOptions: {
            enabled: true,
            placeholder: "Search Role",
          },
          width: "5em",
          sortable: false,
        },
        {
          label: "Last viewed/downloaded",
          field: "created_at",
          filterOptions: {
            enabled: true,
          },
          tdClass: "search-date-selector",
          width: "8em",
          type: "date",
          dateInputFormat: GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
          dateOutputFormat: GOODTABLE_LOCALISED_DATE_FORMAT,
        },
      ],
      columnFilters: [],
      sort: [],
      total: 0,
      rows: [],
      searchTerm: "",
      filterFields: ["participants", "role", "created_at"],
      formStatus,
      formStatusDisplay,
      selected: [],
      selectAll: false,
      bulkSelection: [],
      resourceName: "",
    };
  },
  computed: {
    ...mapGetters("programs", ["defaultProgramId"]),
    directionIsRTL() {
      return store.state.appConfig.isRTL;
    },
  },
  created() {
    this.loadDetail();
    this.loadItems(true);
  },
  mounted() {
    // Allows target by name prefix is vgt-{field-name}
    let datePickers = [];
    datePickers.push(document.getElementsByName("vgt-created_at"));
    datePickers.forEach(function (input) {
      flatPickr(input, {
        enableTime: false,
        dateFormat: GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT,
        allowInput: true,
        altInput: true,
        altFormat: PRIMARY_DATE_FORMAT,
        mode: "range",
      });
    });
  },
  methods: {
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },
    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },
    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== "none") {
        if (params[0].field === "participants") {
          field = "user.full_name";
        } else if (params[0].field === "role") {
          field = "user.user_roles";
        }
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },
    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of this.filterFields) {
        if (params.columnFilters[col]) {
          if (col === "participants") {
            columnFilters.push({
              field: "user.full_name",
              value: params.columnFilters[col],
            });
          } else if (col === "role") {
            columnFilters.push({
              field: "user.user_roles.role.name",
              value: params.columnFilters[col],
            });
          } else if (col === "created_at") {
            const dateRange = params.columnFilters[col].split(" to ");
            if (dateRange.length > 1) {
              const startDate = dateRange[0] + "T00:00:00Z";
              const endDate = dateRange[1] + "T23:59:59Z";
              columnFilters.push({
                field: col,
                value: [startDate, endDate],
              });
            }
          } else {
            columnFilters.push({
              field: col,
              value: params.columnFilters[col],
            });
          }
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },
    async loadItems() {
      try {
        this.isLoading = true;
        const response = await resourcesService.getProgramResourceLogs(
          this.$route.params.id,
          this.$route.params.resource,
          {
            page: this.page,
            perPage: this.perPage,
            columnFilters: this.columnFilters,
            sort: this.sort,
          }
        );
        this.total = response.data.total;
        this.rows = response.data.items.map(i => ({
          ...i,
          participants: i.user.full_name,
          participantId: i.user.id,
          role: i.user.user_roles
            .filter(r => r.role_id !== userRoles.PARTICIPANT_CANDIDATE)
            .map(r => r.role.name)
            .join(","),
        }));
      } catch (e) {
        const { status, data } = e.response;
        //server may respond with vaidation errors
        if (status === 422 && data.errors) {
          this.$refs.observer.setErrors(data.errors);
        } else {
          this.$log.error(e);
        }
        return;
      } finally {
        this.isLoading = false;
      }
    },
    async loadDetail() {
      try {
        const response = await resourcesService.getProgramResourceById(
          this.$route.params.id,
          this.$route.params.resource
        );
        this.resourceName = response.data.translations.length
          ? response.data.translations[0].title
          : "";
      } catch (e) {
      } finally {
      }
    },
  },
  setup() {
    const { total, perPage, page } = useAomTableConfig();
    return {
      total,
      perPage,
      page,
    };
  },
};
</script>
<style lang="scss">
@import "/src/assets/scss/vue-good-table.scss";
</style>
