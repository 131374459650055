var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-row',{attrs:{"align-h":"between","align-v":"center"}},[_c('b-col',{staticClass:"text-left",attrs:{"sm":"4"}},[_c('h4',{staticClass:"m-0 mt-3 mb-3"},[_vm._v(" "+_vm._s(_vm.resourceName)+" ")])])],1),_c('vue-good-table',{staticClass:"position-static",attrs:{"mode":"remote","columns":_vm.columns,"rows":_vm.rows,"rtl":_vm.directionIsRTL,"search-options":{
      enabled: true,
      externalQuery: _vm.searchTerm,
    },"pagination-options":{
      enabled: true,
      perPage: _vm.perPage,
    },"style-class":"vgt-table striped","is-loading":_vm.isLoading},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [(props.column.field === 'checked')?_c('span',[_c('b-form-checkbox',{staticClass:"mr-2 vgt-checkbox-col",attrs:{"value":true,"unchecked-value":false},model:{value:(_vm.selectAll),callback:function ($$v) {_vm.selectAll=$$v},expression:"selectAll"}})],1):_c('span',[_vm._v(" "+_vm._s(props.column.label)+" ")])]}},{key:"table-row",fn:function(props){return [(props.column.field === 'participants')?_c('div',[_c('router-link',{staticClass:"table-primary-link",attrs:{"to":{
            name: 'champion-program-participant',
            params: {
              id: _vm.defaultProgramId,
              participantId: props.row.participantId,
            },
          }}},[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])],1):(props.column.field === 'role')?_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('table-pagination',{attrs:{"per-page":_vm.perPage,"total":_vm.total},on:{"perPageChanged":function (value) { return props.perPageChanged({ currentPerPage: value }); },"pageChanged":function (value) { return props.pageChanged({ currentPage: value }); }}})]}}])},[_c('template',{slot:"emptystate"},[(_vm.isLoading)?_c('div',{staticClass:"text-center"},[_vm._v(" Fetching data ")]):_c('div',{staticClass:"text-center"},[_vm._v(" No data for table ")])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }